---
meta:
    - title: 'Refund Policy'
    - name: description
---

### Dating Studio Refund, Add-Ons, and Usage-Based Costs Policy

**Refund Policy for Subscriptions**

Dating Studio is committed to customer satisfaction. We offer a limited refund for subscriptions under the following conditions:

1. **Refund Eligibility**: If you are not satisfied with our service, you can request a refund within 7 days from the start date of your subscription.

2. **Non-Refundable Costs**: A portion of the subscription fee attributed to AI services is non-refundable due to high costs incurred for AI output generation and feature utilization, including GPU processing expenses.

3. **Refund Calculation**: The refund amount will be the total subscription fee minus the costs associated with the AI services used during the period.

4. **Requesting a Refund**: To request a refund, contact us at hi@dating.studio within the 7-day period with your reason for dissatisfaction. We will process your request according to our policies.

5. **Cancellation**: You may cancel your subscription at any time; however, access to the service continues until the end of the current billing cycle.

**Add-Ons and Usage-Based Costs**

**Add-Ons**

We offer additional features through one-time or monthly subscription add-ons:

1. **Types of Add-Ons**: These include advanced AI features, premium support, and specialized content packs. Details are available on our add-on page.

2. **Purchase and Billing**: Add-ons can be purchased anytime and, if subscribed monthly, will be added to your regular billing cycle.

3. **Non-Refundable**: All add-on purchases are final and non-refundable due to the upfront costs involved.

**Usage-Based Costs**

For access to high-performance AI models such as GPT-4, Claude 3 Opus, and Google Gemini 1.5 Pro:

1. **Billing for Usage**: Costs are calculated based on usage. Rates and billing methods are detailed on our pricing page.

2. **Non-Refundable**: Costs incurred from the use of premium AI models are non-refundable due to significant computational resource expenses.

**General Policy**

-   **No Refunds**: Both add-on purchases and usage-based costs are non-refundable. These costs are paid upfront and are non-recoverable from our providers.

-   **Acknowledgment of Terms**: By purchasing add-ons or using premium AI models, you agree to these terms and acknowledge the non-refundable nature of these services.

For questions or clarifications regarding our policies, please contact us at hi@dating.studio. We aim to provide transparency and fairness in our pricing and policies to ensure you are fully informed about your purchases.

By subscribing to, purchasing add-ons, or using premium AI model services at Dating Studio, you acknowledge that you have read, understood, and agreed to be bound by these terms.
