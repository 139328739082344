---
meta:
    - title: 'Privacy Policy'
---

# Privacy Policy

Hey there! 👋

At Dating Studio, we really care about keeping your information safe and private. We only collect the data we need to give you the best tools and features to help you with online dating. We promise to never share, give, or sell your information to anyone else. We do our very best to keep it protected and secure. 🙅‍♂️ 🔒 💪

Our goal is to create a safe and trustworthy place for everyone who uses our app!

---

**Effective Date:** 2023-April-23

Dating Studio ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Chrome extension, website, and any other services provided by Dating Studio (collectively, the "Service"). By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.

### 1. Information Collection and Use

1.1 **Personal Information:** When you use the Service, we may collect personally identifiable information, such as your name, email address, and IP address. This information is used to provide and improve the Service, and to communicate with you.

1.2 **Chat Data:** When you use our Chrome extension with supported dating apps like Tinder or Bumble, or manually upload chat data from sources like WhatsApp exports or screenshots, we collect and store your chat conversations, match bios, and photos. This data is used to provide AI-augmented features, such as conversation feedback, opening lines, and a "ChatGPT"-like experience within the dating apps.

1.3 **AI Interactions:** We use AI models from Anthropic's Claude, OpenAI's GPT, and Google's Gemini to provide AI-related features. Your interactions with these AI models may be tracked using services like Helicone.

1.4 **Usage Data:** We may collect information about how you access and use the Service, such as your actions within the application, and the dates and times of your visits. This data is collected using services like Sentry for error tracking and Posthog for app and user performance tracking.

1.5 **Use of Information:** The information collected is used solely to provide and improve the core functionality of the Service, which is to help those struggling with online dating to meet their partners. We do not use or share this information with anyone for determining credibility for lending purposes or any other unrelated activities.

1.6 **Legal Basis for Processing:** By registering, checking the consent checkbox, installing the extension, and logging in, you are taking reasonable steps to allow Dating Studio to collect your data. Our legitimate interests for processing your data are to provide the Service and its features to you.

1.7 **Payment Information:** When paid features are introduced, payments will be handled by LemonSqueezy. Dating Studio will never store your card details.

### 2. Data Storage and Security

2.1 **Data Storage:** Your data is primarily stored in Germany, Europe, but may be replicated around the globe based on your location determined by your IP address. Data may be replicated outside the EU for non-EU users to ensure fast loading times.

2.2 **Data Security:** All data transmitted between your device and our servers is encrypted using industry-standard TLS. We take reasonable measures to protect your information from unauthorized access, alteration, or destruction. All data is encrypted until it reaches the user's browser using HTTPS/TLS and is stored on highly guarded servers.

### 3. Data Sharing and Disclosure

3.1 **Third-Party Services:** We may share your data with third-party services, such as Sentry, Posthog, Helicone, Anthropic, OpenAI, and Google, to provide and improve the Service. These services have their own privacy policies, which we encourage you to review.

3.2 **Legal Requirements:** We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to comply with legal obligations, protect and defend our rights or property, or act in urgent circumstances to protect the personal safety of users of the Service or the public.

3.3 **Sale of Data:** We do not sell your data to third parties.

### 4. Data Retention and Deletion

4.1 **Data Visibility:** By default, your chat data is only visible to you. However, you may choose to share your data with others at your own discretion.

4.2 **Data Deletion:** You may delete your chat data at any time by manually deleting the chats or by deleting your account. Once you delete your account, we will make commercially reasonable efforts to remove your personally identifiable information within 90 days.

4.3 **Data Retention:** We retain your data for as long as you use the Service. Inactive users may have their data deleted after 2 years.

### 5. Your Rights and Choices

5.1 **Access and Rectification:** You have the right to access and rectify your personal data. You can update your information directly within the Service or by contacting us at the email address provided below.

5.2 **Data Portability:** You have the right to receive a copy of your personal data in a structured, commonly used, and machine-readable format.

5.3 **Objection and Restriction:** You have the right to object to or restrict the processing of your personal data. Please contact us at the email address provided below to exercise these rights.

5.4 **Withdrawal of Consent:** If you have provided consent for the processing of your personal data, you have the right to withdraw your consent at any time. You can do this by deleting the extension, deleting your account, stopping use of the Service, or by contacting us at the email address provided below.

5.5 **Lodging a Complaint:** If you believe your rights under the GDPR have been infringed, you have the right to lodge a complaint with a supervisory authority. We encourage you to contact us first so that we can address your concerns.

### 6. Data Breaches

In the event of a data breach, we will inform you via email as soon as we become aware of it.

### 7. Age Limit

The Service is not intended for use by anyone under the legal age, such as 18 in most countries, but this may be higher depending on your legal residence. It also wouldn't make sense for a younger user to use the Service since dating apps generally have the same age requirements.

### 8. Changes to This Privacy Policy

We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.

### 9. Contact Us

If you have any questions about this Privacy Policy, please contact us at privacy@dating.studio.

### 10. Disclaimer and Limitation of Liability

The Service is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Service or the information, content, materials, or products included in the Service. You expressly agree that your use of the Service is at your sole risk. In no event shall Dating Studio be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of or in connection with the use of the Service.

### 11. Third-Party Trademarks

All third-party labels and names, including Tinder, Bumble, Anthropic, OpenAI, Google, and others, are registered trademarks of their respective owners. Dating Studio is not affiliated with, sponsored by, or endorsed by any of these third parties.

By using the Dating Studio Service, you agree to be bound by this Privacy Policy.
