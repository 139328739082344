---
meta:
    - title: 'Terms of Service'
    - name: description
---

# Terms of Service

Last Updated: 2024-06-14

These Terms of Service ("Terms") govern your use of the Dating Studio website, Chrome extension, and any other services provided by Dating Studio (collectively, the "Service"). The Service is developed and offered by Eloquent Labs Group LLC, a company registered at 167 Madison Avenue, Suite 205 #1055, New York, NY, 10016, UNITED STATES. By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms or the Privacy Policy, you may not use the Service.

## 1. Acceptance of Terms

By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy, which is incorporated herein by reference. If you do not agree to these Terms or the Privacy Policy, you may not use the Service.

## 2. Description of the Service

Dating Studio provides a platform that utilizes AI models and a knowledge base to provide users with advice and tips for online dating. The Service includes a Chrome extension that integrates with dating apps, as well as AI-powered features such as conversation feedback, opening lines, and an AI-assisted chat experience within the dating apps, conversation library, fast chat, importer from screenshots, personalized completion, writing assistant etc.

## 3. User Obligations and Responsibilities

To use the Service, you must be at least 18 years old or the legal age of majority in your jurisdiction. By using the Service, you agree not to:

-   Upload, post, or transmit any content that is illegal, abusive, harmful, or infringes on the rights of others.
-   Use the Service to stalk, harass, or harm another person.
-   Use the Service for any illegal or unauthorized purpose.
-   Interfere with or disrupt the Service or servers or networks connected to the Service.
-   Use any automated means to access the Service for any purpose without our express written permission.

## 4. Intellectual Property

All intellectual property rights related to the Service, including copyrights, trademarks, and patents, are the property of Dating Studio. You may not copy, modify, distribute, or create derivative works of any content from the Service without our prior written consent.

Users retain ownership of the content they create and upload to the Service. By uploading content, you grant Dating Studio a non-exclusive, royalty-free, and worldwide license to use, reproduce, and display the content within the Service.

Users may purchase packs of conversations sold by third parties or curated by Dating Studio from publicly available sources. These conversations are available for use within the Service, but not for download, and the copyright is retained by the respective owners as indicated within the conversations.

## 5. Third-Party Services and Content

The Service may contain links to third-party websites or services. Dating Studio is not responsible for the content or actions of these third parties. Your use of third-party services is subject to their respective terms and conditions.

## 6. Disclaimer of Warranties

The Service is provided "as is" and without warranties of any kind, express or implied. Dating Studio does not make any representations or warranties of any kind, including but not limited to the completeness, accuracy, reliability, suitability, or availability of the Service or its content.

## 7. Limitation of Liability

To the fullest extent permitted by applicable law, Dating Studio shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.

## 8. Indemnification

You agree to indemnify, defend, and hold harmless Dating Studio and its affiliates, officers, agents, employees, and partners from any claim, demand, liability, damages, losses, costs, and expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Service, your violation of these Terms, or your violation of any rights of another.

## 9. Termination

Dating Studio reserves the right, at its sole discretion, to terminate or suspend your access to the Service at any time and for any reason, including but not limited to your violation of these Terms or engaging in illegal, abusive, or harmful activities while using the Service. If your account is terminated, you will lose access to the Service and any data associated with your account.

## 10. Modifications to the Terms

Dating Studio reserves the right to modify or replace these Terms at any time. You are responsible for regularly reviewing these Terms for any changes or updates. Your continued use of the Service after any changes to the Terms constitutes your acceptance of the updated Terms.

## 11. Governing Law and Dispute Resolution

These Terms shall be governed by and construed in accordance with the laws of Dubai, without giving effect to any principles of conflicts of law. Any dispute arising out of or in connection with these Terms shall be resolved through amicable negotiations. If the dispute cannot be resolved through negotiations, it shall be submitted to the exclusive jurisdiction of the courts of Dubai.

## 12. Entire Agreement and Severability

These Terms, together with the Privacy Policy, constitute the entire agreement between you and Dating Studio regarding the use of the Service. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.

## 13. Payment and Refunds

Dating Studio may offer paid features or services. The details of these paid features, including pricing and accepted payment methods, will be listed on a dedicated pricing page. Payments for these features are processed by Lemon Squeezy, a third-party payment platform.

Refunds are not offered for paid features because AI computing incurs a reasonable cost on GPUs, which is non-refundable. However, if you believe that the service did not function as expected, you may contact Dating Studio at hi@dating.studio to discuss potential solutions.

## 14. Data Protection and Privacy

Dating Studio is committed to protecting your privacy and ensuring the security of your personal information. Please refer to our [Privacy Policy](/privacy-policy) for detailed information on how we collect, use, and protect your data.

## 15. User-Generated Content

You are solely responsible for the content you upload, post, or transmit through the Service. Dating Studio reserves the right to remove any content that violates these Terms or is deemed inappropriate, at its sole discretion.

## 16. Trademarks

All trademarks, logos, and service marks displayed on the Service are the property of their respective owners. Dating Studio's use of these trademarks does not imply any affiliation with or endorsement by the trademark owners. The following trademarks are the property of their respective owners:

-   OpenAI® and the OpenAI logo are registered trademarks of OpenAI Inc.
-   Anthropic™ and the Anthropic logo are trademarks of Anthropic, PBC.
-   Google®, the Google logo, and all related product and service names, logos, and slogans are trademarks of Google LLC.
-   Tinder® and the Tinder logo are registered trademarks of Match Group, LLC.
-   Bumble® and the Bumble logo are registered trademarks of Bumble Holding Limited.
-   Instagram® and the Instagram logo are registered trademarks of Instagram, LLC.
-   WhatsApp® and the WhatsApp logo are registered trademarks of WhatsApp Inc.

Dating Studio is not affiliated with, sponsored by, endorsed by, or otherwise associated with any of the aforementioned companies or any other third-party entities, and the use of their trademarks does not imply any relationship or promotion by these entities.

## 17. Contact Us

If you have any questions or concerns about these Terms or the Service, please contact us at hi@dating.studio. We will endeavor to resolve any issues and ensure a satisfactory user experience.

By using the Dating Studio Service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
