import { useLoaderData } from '@remix-run/react';
import type { LoaderFunctionArgs } from '@remix-run/server-runtime';

const posts = import.meta.glob('../posts/*.mdx', { eager: true });

export async function loader({ params }: LoaderFunctionArgs) {
    const { slug } = params;

    // if (!slug || posts[`../posts/${slug}.mdx`] === undefined)
    //     throw new Response(null, {
    //         status: 404,
    //         statusText: 'Not Found',
    //     });

    return { slug };
}

export default function DynamicPost() {
    const { slug } = useLoaderData<typeof loader>();
    const postPath = `../posts/${slug}.mdx`;
    const Post = posts[postPath] as
        | { default: React.ComponentType }
        | undefined;

    return (
        <div className="py-32 px-4">
            <main className="prose mx-auto">
                {Post ? <Post.default /> : <p>Not found</p>}
            </main>
        </div>
    );
}
